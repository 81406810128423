import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Navigate, Route, Routes } from "react-router-dom";
import { useLocation, useNavigate } from "react-router";

import { ToastContainer } from "react-toastify";
import ExpertsDashboard from "../../pages/dashboard/ExpertsDashboard";
import Market from "../../pages/Market";
import Futures from "../../pages/future/Futures";
import Login from "../../pages/Login";
import CryptoBasket from "../../pages/cryptobasket/CryptoBasket";
import CryptoBasketDetails from "../../pages/cryptobasket/CryptoBasketDetails";
import Test from "../../pages/cryptobasket/Test";
import ExpertsCryptoFund from "../../pages/cryptoFund/ExpertsCryptoFund";
import CryptoFundDetails from "../../pages/cryptoFund/CryptoFundDetails";
import SpotDetailsPage from "../../pages/spot/SpotDetailsPage";
import FutureDetailsPage from "../../pages/future/FutureDetailsPage";
import Spot from "../../pages/spot/Spot";
import Tokens from "../../pages/Tokens/Tokens";
import { TokenDetailsPage } from "../../pages/Tokens/TokenDetailsPage";
import Wallets from "../../pages/Wallets/Wallets";
import UsersDashboard from "../../pages/dashboard/UsersDashboard";
import UsersCryptoFund from "../../pages/cryptoFund/UsersCryptoFund";
import PortFolio from "../../pages/portfolio/PortFolio";
import CommunityRewards from "../../pages/communityRewards/CommunityRewards";
import Refer from "../../pages/refer/Refer";
import Welcome from "../../pages/welcome/Welcome";

export const RouterComponent = () => {
  const appCtx = useSelector((state) => state.app);

  useEffect(() => {
    let link = document.querySelector("link[rel~='icon']");
    if (!link) {
      link = document.createElement("link");
      link.rel = "shortcut icon";
      document.getElementsByTagName("head")[0].appendChild(link);
    }
    link.href =
      "https://taleinvest.blob.core.windows.net/tale-invest-assets/tale-invest-logo-sm.png";
  }, []);

  console.log("appCtx.isLoggedIn", appCtx.isLoggedIn);

  return (
    <>
      <Routes>
        <Route
          path="/"
          element={
            !appCtx.isLoggedIn ? (
              <Navigate to={"/login"} />
            ) : appCtx?.role === "user" || appCtx?.role === "expertNormal" ? (
              <UsersDashboard />
            ) : (
              <ExpertsDashboard />
            )
          }
        />
        <Route
          path="/crypto-fund"
          element={
            !appCtx.isLoggedIn ? (
              <Navigate to={"/login"} />
            ) : appCtx?.role === "user" || appCtx?.role === "expertNormal" ? (
              <UsersCryptoFund />
            ) : (
              <ExpertsCryptoFund />
            )
          }
        />
        <Route path="/login" element={<Login />} />
        <Route path="/signup" element={<Login />} />

        <Route
          path="/market"
          element={!appCtx.isLoggedIn ? <Navigate to="/login" /> : <Market />}
        />
        <Route
          path="/welcome"
          element={!appCtx.isLoggedIn ? <Navigate to="/login" /> : <Welcome />}
        />

        {/* Spot Route */}
        <Route
          path="/spot"
          element={!appCtx.isLoggedIn ? <Navigate to="/login" /> : <Spot />}
        />

        {/* Futures Route */}
        <Route
          path="/future"
          element={!appCtx.isLoggedIn ? <Navigate to="/login" /> : <Futures />}
        />

        {/* Crypto Basket Route */}
        <Route
          path="/crypto-basket"
          element={
            !appCtx.isLoggedIn ? <Navigate to="/login" /> : <CryptoBasket />
          }
        />

        {/* Portfolio Route */}
        <Route
          path="/portfolio"
          element={
            !appCtx.isLoggedIn ? <Navigate to="/login" /> : <PortFolio />
          }
        />

        <Route
          path="/wallet"
          element={
            !appCtx.isLoggedIn ? <Navigate to={"/login"} /> : <Wallets />
          }
        />
        <Route
          path="/test"
          element={!appCtx.isLoggedIn ? <Navigate to={"/login"} /> : <Test />}
        />

        <Route
          path="/community-rewards"
          element={
            !appCtx.isLoggedIn ? (
              <Navigate to={"/login"} />
            ) : (
              <CommunityRewards />
            )
          }
        />
        <Route
          path="/refer-and-earn"
          element={!appCtx.isLoggedIn ? <Navigate to={"/login"} /> : <Refer />}
        />
        <Route
          path="/crypto-basket/:id"
          element={
            !appCtx.isLoggedIn ? (
              <Navigate to={"/login"} />
            ) : (
              <CryptoBasketDetails />
            )
          }
        />
        <Route
          path="/crypto-fund/:id"
          element={
            !appCtx.isLoggedIn ? (
              <Navigate to={"/login"} />
            ) : (
              <CryptoFundDetails />
            )
          }
        />
        <Route
          path="/tokens"
          element={!appCtx.isLoggedIn ? <Navigate to={"/login"} /> : <Tokens />}
        ></Route>
        <Route
          path="/token/:tokenid/:tokenname"
          element={
            !appCtx.isLoggedIn ? (
              <Navigate to={"/login"} />
            ) : (
              <TokenDetailsPage />
            )
          }
        ></Route>

        <Route
          path="/spot/:tokenid/:tokenname"
          element={
            !appCtx.isLoggedIn ? <Navigate to={"/"} /> : <SpotDetailsPage />
          }
        ></Route>
        <Route
          path="/future/:tokenid/:tokenname"
          element={
            !appCtx.isLoggedIn ? <Navigate to={"/"} /> : <FutureDetailsPage />
          }
        ></Route>
      </Routes>
      <ToastContainer
        position="bottom-left"
        autoClose={5000}
        hideProgressBar={false}
        newestOnTop={true}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable={false}
        pauseOnHover
        theme={appCtx.isDarkMode ? "dark" : "light"}
        toastStyle={
          appCtx.isDarkMode
            ? {
                backgroundColor: "#27272A",
                color: "#E2E8F0",
              }
            : { backgroundColor: "#F8FAFC", color: "#1F2937" }
        }
      />
    </>
  );
};
