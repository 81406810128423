import React, { useEffect, useState } from "react";
import Modal from "react-modal";
import { useSelector } from "react-redux";
import {
  faCheck,
  faChevronDown,
  faCopy,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { CircularProgress } from "@mui/material";
import moment from "moment";
import Accordion from "@mui/material/Accordion";
import AccordionSummary from "@mui/material/AccordionSummary";
import AccordionDetails from "@mui/material/AccordionDetails";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import { BiChevronDown } from "react-icons/bi";
import { AiOutlineSearch } from "react-icons/ai";
import { useWalletView } from "../../hooks/useWalletView";
import useAuthorizedHttp from "../../hooks/use-authorized-http";
import { nativeToken } from "../../config";
import { Card } from "react-bootstrap";
import { usehandleBuyTaleCoin } from "../../hooks/handleBuyTaleCoin";

const CryptoFundOnRampModal = ({
  openModal,
  setOpenModal,
  amount,
  basketId,
  tokenPercentArray,
  tokenArray,
  basketBlockchain,
  isTokenDetailsPage,
  symbol,
  totalInvestment,
  singleBasket,
  address,
  blockchain,
  name,
  fundId,
  tokenPriceCurrency,
  tokenPrice,
  contractAddress,
  fundsBlockchain,
  truncatedAddress,
  fundWalletAddress,
}) => {
  const appCtx = useSelector((state) => state.app);
  const [sendToken, setSendToken] = useState("");
  const [receiveTokenPrice, setReceiveTokenPrice] = useState(null);
  const [sendAmount, setSendAmount] = useState(amount || 5000);
  const [isCopied, setIsCopied] = useState(false);
  const [isBankDataCopied, setIsBankDataCopied] = useState([
    false,
    false,
    false,
    false,
    false,
    false,
  ]);

  const blockchainFromMobile = new URLSearchParams(window.location.search).get(
    "blockchain"
  );

  const [receiveToken, setReceiveToken] = useState(
    symbol || nativeToken[blockchain || appCtx.blockchain]
  );

  const [inputValue, setInputValue] = useState("");
  const [open, setOpen] = useState(false);

  const { handleBuyAlgos } = usehandleBuyTaleCoin(appCtx);

  const networks = {
    POLYGON: "matic20",
    BSC: "bep20",
    ETHEREUM: "erc20",
    ALGORAND: "algo",
    SOLANA: "sol",
  };
  const tokensData = [
    {
      tokenId: 0,
      contractAddress: "",
      tokenShortName: "INR",
      tokenName: "Indian Rupee",
      tokenIcon:
        "https://png.pngtree.com/png-clipart/20220804/ourmid/pngtree-indian-flag-pin-badge-png-image_6098458.png",
      symbol: "INR",
    },
    // {
    //   tokenId: 8,
    //   contractAddress: "0xc2132d05d31c914a87c6611c10748aeb04b58e8f",
    //   tokenShortName: "USDT",
    //   tokenName: "USDT",
    //   tokenIcon:
    //     "https://talewallet-assets.s3.ap-southeast-1.amazonaws.com/token-icon/USDT.png",
    //   symbol: "USDT",
    // },
    // {
    //   tokenId: 6,
    //   contractAddress: "0x8ac76a51cc950d9822d68b83fe1ad97b32cd580d",
    //   tokenShortName: "USDC",
    //   tokenName: "USDC",
    //   tokenIcon:
    //     "https://talewallet-assets.s3.ap-southeast-1.amazonaws.com/token-icon/USDC.png",
    //   symbol: "USDC",
    // },
  ];

  const hardCodeGasFee = {
    POLYGON: 10,
    BSC: 30,
    ETHEREUM: 300,
    ALGORAND: 0,
    SOLANA: 10,
  };

  var network;
  network =
    networks[blockchainFromMobile || basketBlockchain || appCtx.blockchain];

  const [receiveAmount, setReceiveAmount] = useState(null);
  const [currentComponent, setCurrentComponent] = useState(1);

  const [upiClicked, setUpiClicked] = useState(false);
  const [bankClicked, setBankClicked] = useState(false);
  const [isChecked, setIsChecked] = useState(false);
  const [fourDigit, setFourDigit] = useState(null);
  const [loader, setLoader] = useState(false);
  const [networkFee, setNetworkFee] = useState(0);
  const [isBasketDetailsPage, setIsBasketDetailsPage] = useState(false);
  const [receiveAmountLoading, setReceiveAmountLoading] = useState(false);
  const [receiveTokenName, setReceiveTokenName] = useState(null);
  const [isAddressCopied, setIsAddressCopied] = useState(false);
  const [expanded, setExpanded] = useState(null);

  const { getAllCoin, getAllCoinPrice, allCoin, allCoinPrice } =
    useWalletView();

  const makeRequest = useAuthorizedHttp();
  const queryParams = new URLSearchParams(window.location.search);

  let isOpenedfromMobile = queryParams.get("address");

  function closeModal() {
    setOpenModal(false);
  }

  const userAddress = appCtx?.walletAddress?.find(
    (addr) => addr?.blockchain === appCtx?.blockchain
  )?.address;
  const customStyles = {
    overlay: {
      backgroundColor: "rgba(0, 0, 0, 0.7)",
      zIndex: 9998,
    },
    content: {
      top: "53%",
      backgroundColor: `${appCtx?.isDarkMode ? "#09153F" : "#FFFFFF"}`,
      border: `1px solid ${appCtx?.isDarkMode ? "#CCCCFF" : "#FFFFFF"}`,
      color: `${appCtx?.isDarkMode ? "white" : "black"}`,
      left: "50%",
      right: "auto",
      bottom: "auto",
      marginRight: "-50%",
      padding: "0",
      borderRadius: "10px",
      transform: "translate(-50%, -50%)",
      overflow: "hidden",
    },
  };
  const formattedDate = moment().format("D MMM'YY, h:mm a");

  useEffect(() => {
    const url = window.location.href;
    const searchStrings = [
      "subscribe-crypto-basket",
      "crypto-basket",
      "basketid",
    ];
    let isPageFound = false;

    for (const searchString of searchStrings) {
      if (url.includes(searchString)) {
        isPageFound = true;
        break;
      }
    }

    setIsBasketDetailsPage(isPageFound);
  }, []);

  const themedColor = {
    color: `${appCtx?.isDarkMode ? "white" : "black"}`,
  };

  const handleCheckboxChange = () => {
    setIsChecked(!isChecked);
  };
  const handleBankDataCopy = (value, index) => {
    navigator.clipboard.writeText(value);
    const updatedCopiedState = [...isBankDataCopied];
    updatedCopiedState[index] = true;
    setIsBankDataCopied(updatedCopiedState);
  };

  // fetching receive token details

  useEffect(() => {
    setReceiveAmountLoading(true);
    if (sendAmount < 5000) {
      toast.error("Minimum amount is 5000");
    } else {
      if (sendAmount >= 5000) {
        makeRequest(
          {
            url: `${process.env.REACT_APP_URL_BLOCKCHAIN_SERVICE}/token/quote`,
            data: {
              fiatAmount: sendAmount,
              token: receiveToken,
              network: network,
            },
            method: "post",
            headers: {
              "X-App-Token": process.env.REACT_APP_X_APP_TOKEN,
              "Content-Type": "application/json",
            },
          },
          (data) => {
            setReceiveTokenPrice(
              data?.data?.rate ||
                appCtx?.singleTokenData?.priceOfToken * appCtx?.usdPrice
            );

            console.log("is quantify available", data?.data?.quantity);
            console.log("appCtx?.usdPrice", appCtx?.usdPrice);
            console.log(
              " sendAmount / appCtx?.singleTokenData?.priceOfToken * appCtx?.usdPrice",
              sendAmount /
                (appCtx?.singleTokenData?.priceOfToken * appCtx?.usdPrice)
            );

            if (data?.data?.quantity) {
              setReceiveAmount(data?.data?.quantity);
            } else {
              setReceiveAmount(
                sendAmount /
                  (appCtx?.singleTokenData?.priceOfToken * appCtx?.usdPrice)
              );
            }
            setNetworkFee(
              data?.data?.gasFee || hardCodeGasFee[appCtx?.blockchain]
            );
            setReceiveAmountLoading(false);
          },
          () => {
            setReceiveAmountLoading(false);
          },
          (error) => {
            setReceiveAmountLoading(false);
          }
        );
      }
    }
  }, [sendAmount, receiveToken]);

  const handleCopyAddress = (value) => {
    setIsAddressCopied(!isAddressCopied);
    navigator.clipboard.writeText(value);
  };
  useEffect(() => {
    const min = 1000; // Minimum 4-digit number
    const max = 9999; // Maximum 4-digit number
    const fourDigit = Math.floor(Math.random() * (max - min + 1)) + min;
    setFourDigit(fourDigit);
  }, []);

  let platformFee;
  let totalFee;

  if (sendAmount !== null && sendAmount !== undefined) {
    platformFee = ((sendAmount * 0.4) / 100).toFixed(2);
  } else {
    // Handle the case when sendAmount is null or undefined
    // For example:
    platformFee = "0.00";
  }

  if (networkFee !== null && networkFee !== undefined) {
    totalFee = (parseFloat(platformFee) + parseFloat(networkFee)).toFixed(2);
  } else {
    // Handle the case when networkFee is null or undefined
    // For example:
    totalFee = platformFee;
  }

  const handlePaid = () => {
    setLoader(true);
    makeRequest(
      {
        url: `${process.env.REACT_APP_URL_BLOCKCHAIN_SERVICE}/inhouse/onramp/transaction`,
        data: {
          fiatAmount: sendAmount,

          fiatCurrency: "INR",

          cryptoToken: tokenPriceCurrency,

          cryptoAmount: sendAmount / (tokenPrice * appCtx?.usdPrice),

          blockchain: fundsBlockchain,

          note: fourDigit,
          platformFee: platformFee,
          networkFee: networkFee,
          tokenPrice: tokenPrice,
          address: userAddress,
          type: "cryptoFundOnramp",
          fundId: fundId,
        },
        method: "post",
      },
      (data) => {
        setLoader(false);
        setCurrentComponent(3);
        // setOpenModal(false);
      },
      (error) => {
        // setLoader(false);
      },
      () => {}
    );
  };

  const navigate = useNavigate();
  let params = {
    walletAddress: [
      {
        address: queryParams.get("address"),
      },
    ],
  };

  useEffect(() => {
    getAllCoin();
    getAllCoinPrice();
  }, []);

  const handleSendAmountChange = (event) => {
    setSendAmount(event.target.value);
  };

  return (
    <>
      <Modal
        isOpen={openModal}
        style={customStyles}
        contentLabel="Example Modal"
        onRequestClose={closeModal}
        ariaHideApp={false}
      >
        <div
          class=" d-flex justify-content-between align-items-center  py-2 position-relative px-4 rounded-top-lg"
          style={{
            backgroundColor: "#9376B1",
            borderRadius: "5px 5px 0px 0px",
          }}
        >
          <div class="w-15%">
            <img
              src="https://taleinvest.blob.core.windows.net/tale-invest-assets/tale-invest-logo-sm.png"
              alt=""
              style={{ width: "35px", height: "35px" }}
            />
          </div>

          <h5 class="mb-0">Talewallet</h5>
          <div class="">
            <div
              className="btn btn-link"
              onClick={() => {
                closeModal();
              }}
              style={{ cursor: "pointer" }}
            >
              <i className="fa fa-times" style={{ fontSize: "20px" }}></i>
            </div>
          </div>
        </div>
        <div
          class="overflow-auto bg-EEEEEE rounded-xl"
          style={{ maxHeight: "70vh" }}
        >
          {currentComponent === 1 && (
            <div style={{ width: "400px" }}>
              <div class="px-4 py-2">
                <p class="text-md my-2">Enter the amount</p>

                <div
                  className="mb-3 d-flex justify-content-between align-items-center py-1 border"
                  style={{ border: "#ced4da", borderRadius: "5px" }}
                >
                  <div className="">
                    <input
                      id="sendAmount"
                      placeholder="Enter amount"
                      name="sendAmount"
                      type="number"
                      min="0"
                      value={sendAmount}
                      onChange={handleSendAmountChange}
                      className="ms-3"
                      style={{
                        backgroundColor: `${
                          appCtx?.isDarkMode ? "#09153F" : "white"
                        }`,
                        color: `${appCtx?.isDarkMode ? "white" : "black"}`,
                        width: "100px",
                        border: "none",
                      }}
                    />
                  </div>
                  <div class="w-2 h-12 d-flex justify-content-center align-items-center">
                    <div
                      class="border border-end"
                      style={{
                        width: "2px",
                        height: "30px",
                        border: "#ced4da",
                      }}
                    ></div>
                  </div>

                  <div
                    id="sendToken"
                    name="sendToken"
                    class="d-flex align-items-center gap-2 ms-2 "
                    style={{ width: "150px" }}
                  >
                    <img
                      src="/images/indianflag.png"
                      alt=""
                      class=""
                      style={{ width: "25px", height: "25px" }}
                    />
                    <div class="">
                      <p class="mb-0">INR</p>
                      <p class="mb-0">Indian Rupee</p>
                    </div>
                  </div>
                </div>

                <p class="text-md mt-2 mb-2">Minimum investment is 5000 INR</p>
                <div className="">
                  <Accordion className="border">
                    <AccordionSummary
                      as={Card.Header}
                      eventKey="0"
                      className="d-flex justify-content-between align-items-center"
                      style={{
                        backgroundColor: `${
                          appCtx?.isDarkMode ? "#09153F" : "white"
                        }`,
                        color: `${appCtx?.isDarkMode ? "white" : "black"}`,
                      }}
                      onClick={() => setExpanded(!expanded)}
                    >
                      <p className="text-[#3B0076] font-medium text-md mb-0">
                        You Pay ₹{sendAmount}
                      </p>
                      <p className="ms-4 mb-0">View Breakup</p>
                      <div className="d-flex align-items-center ms-2">
                        <FontAwesomeIcon
                          icon={faChevronDown}
                          rotation={expanded ? 180 : 0}
                          className="ms-1"
                          style={themedColor}
                        />
                      </div>
                    </AccordionSummary>
                    <AccordionDetails
                      eventKey="0"
                      style={{
                        backgroundColor: `${
                          appCtx?.isDarkMode ? "#09153F" : "white"
                        }`,
                        color: `${appCtx?.isDarkMode ? "white" : "black"}`,
                      }}
                    >
                      <>
                        <div className=" text-sm font-medium">
                          <div className="d-flex justify-content-between align-items-center ">
                            <p className="mb-1">Platform Fee (0.4%)</p>
                            <p className="mb-1">₹{platformFee || 0}</p>
                          </div>
                          <div className="d-flex justify-content-between align-items-center">
                            <p className="mb-1">Network Fee</p>
                            <p className="mb-1"> ₹{networkFee || 0}</p>
                          </div>
                        </div>
                        <hr />
                        <div className="d-flex justify-content-between align-items-center text-sm font-medium">
                          <p className="mb-1">Total Fee</p>
                          <p className="mb-1">₹{totalFee || 0}</p>
                        </div>
                        {isBasketDetailsPage && (
                          <p className="text-sm font-medium mt-1">
                            Network fee includes{" "}
                            {tokenArray?.map((token, index) => {
                              const isLastToken =
                                index === tokenArray.length - 1;
                              return (
                                <span
                                  key={index}
                                  className="text-sm font-medium ml-2"
                                >
                                  {nativeToken[basketBlockchain]} to {token}
                                  {!isLastToken &&
                                    index !== tokenArray.length - 2 &&
                                    ", "}
                                  {!isLastToken &&
                                    index === tokenArray.length - 2 &&
                                    " and "}
                                </span>
                              );
                            })}
                          </p>
                        )}
                      </>
                    </AccordionDetails>
                  </Accordion>
                </div>

                <div class="row mt-4 align-items-center">
                  <div class="col">
                    <button
                      onClick={() => {
                        setCurrentComponent(2);
                        setUpiClicked(true);
                      }}
                      disabled={!sendAmount || receiveAmountLoading}
                      class="w-100 mb-3 py-2 text-md font-weight-bold text-white bg-success border border-success rounded active:text-secondary  focus:outline-none focus:ring"
                    >
                      UPI
                    </button>
                  </div>
                  <div class="col">
                    <button
                      onClick={() => {
                        setCurrentComponent(2);
                        setBankClicked(true);
                      }}
                      disabled={!sendAmount || receiveAmountLoading}
                      class="w-100 mb-3 py-2 text-md font-weight-bold text-white bg-primary border border-primary rounded active:text-secondary hover:bg-transparent hover:text-primary focus:outline-none focus:ring"
                    >
                      BANK
                    </button>
                  </div>
                </div>
              </div>
            </div>
          )}
          {currentComponent === 2 && (
            <div className="p-3">
              {upiClicked && (
                <div class=" border d-flex flex-column gap-3 justify-content-center align-items-center p-5 mb-4">
                  <p class="text-primary font-weight-bold text-md">
                    Send ₹{sendAmount}
                  </p>
                  <img
                    src="/images/onrampqrcode.jpg"
                    alt="qrcode"
                    style={{ width: "120px", height: "120px" }}
                  />
                  <div class="d-flex align-items-center gap-3">
                    <p class="text-primary font-weight-semibold">
                      UPI-ID:
                      <span class="text-primary font-weight-bold">
                        talewallet@kotak
                      </span>
                    </p>
                    <button
                      onClick={() => {
                        navigator.clipboard.writeText(`talewallet@kotak`);
                        setIsCopied(true);
                      }}
                      class="px-3 text-secondary"
                    >
                      <FontAwesomeIcon icon={isCopied ? faCheck : faCopy} />
                    </button>
                  </div>
                </div>
              )}
              {bankClicked && (
                <div class=" border d-flex flex-column gap-3 justify-content-center align-items-center p-2 mb-2">
                  <p class="text-violet-900 font-weight-bold text-md">
                    Send ₹{sendAmount}
                  </p>
                  <div class="text-violet-700 text-xs">
                    <table class="table table-borderless">
                      <tbody>
                        <tr>
                          <td class="py-1 pl-2">Name</td>
                          <td class="py-1 pl-2">
                            Nextales Technology Private Limited
                          </td>
                          <td class="py-2 px-2">
                            <button
                              onClick={() =>
                                handleBankDataCopy(
                                  " Nextales Technology Private Limited",
                                  0
                                )
                              }
                              class="px-3 text-secondary"
                            >
                              <FontAwesomeIcon
                                icon={isBankDataCopied[0] ? faCheck : faCopy}
                              />
                            </button>
                          </td>
                        </tr>
                        <tr>
                          <td class="py-1 pl-2">Account Number</td>
                          <td class="py-1 pl-2">1947549911</td>
                          <td class="py-1 px-2">
                            <button
                              onClick={() =>
                                handleBankDataCopy("1947549911", 1)
                              }
                              class="px-3 text-secondary"
                            >
                              <FontAwesomeIcon
                                icon={isBankDataCopied[1] ? faCheck : faCopy}
                              />
                            </button>
                          </td>
                        </tr>
                        <tr>
                          <td class="py-1 pl-2">IFSC Code</td>
                          <td class="py-1 pl-2">KKBK0000958</td>
                          <td class="py-2 px-2">
                            <button
                              onClick={() =>
                                handleBankDataCopy("KKBK0000958", 2)
                              }
                              class="px-3 text-secondary"
                            >
                              <FontAwesomeIcon
                                icon={isBankDataCopied[2] ? faCheck : faCopy}
                              />
                            </button>
                          </td>
                        </tr>
                        <tr>
                          <td class="py-1 pl-2">Type</td>
                          <td class="py-1 pl-2">Current</td>
                          <td class="py-2 px-2">
                            <button
                              onClick={() => handleBankDataCopy("Current", 3)}
                              class="px-3 text-secondary"
                            >
                              <FontAwesomeIcon
                                icon={isBankDataCopied[3] ? faCheck : faCopy}
                              />
                            </button>
                          </td>
                        </tr>
                        <tr>
                          <td class="py-1 pl-2">Bank</td>
                          <td class="py-1 pl-2">Kotak Mahindra Bank</td>
                          <td class="py-2 px-2">
                            <button
                              onClick={() =>
                                handleBankDataCopy("Kotak Mahindra Bank", 5)
                              }
                              class="px-3 text-secondary"
                            >
                              <FontAwesomeIcon
                                icon={isBankDataCopied[5] ? faCheck : faCopy}
                              />
                            </button>
                          </td>
                        </tr>
                      </tbody>
                    </table>
                  </div>
                </div>
              )}

              <div className="text-sm text-violet-900  ">
                <p className="text-red-500  text-md">
                  Add code <b>{fourDigit}</b> in the message while transfering
                </p>
                <p>Use GPay, PhonePe, Paytm app or IMPS through bank app </p>
                <div className=" mt-2">
                  <p>
                    After making the payment, click on "I HAVE PAID" button{" "}
                  </p>
                </div>
              </div>

              {!loader ? (
                <button
                  onClick={() => {
                    handlePaid();
                  }}
                  className="btn btn-primary w-100"
                >
                  I HAVE PAID
                </button>
              ) : (
                <button className="btn btn-primary w-100">
                  <CircularProgress size={16} />
                </button>
              )}
            </div>
          )}
          {currentComponent === 3 && (
            <div className="p-2">
              <p className="">Verifying your payment</p>
              <p className="">
                ₹{sendAmount} * {formattedDate}
              </p>
              <p className="">Expected time duration 24 hour</p>

              <button
                onClick={() => {
                  closeModal();
                }}
                className="btn btn-primary w-100 "
              >
                Okay
              </button>
            </div>
          )}

          <div
            className="d-flex flex-column align-items-center mx-auto px-4 pb-2"
            style={{ width: "400px" }}
          >
            <p className="text-sm mb-0">
              {sendAmount} {sendToken} will be transferred to {name}'s wallet.
            </p>
            <div className="d-flex flex-row align-items-center gap-2 my-1">
              <span className="font-weight-medium text-small">
                Wallet Address
              </span>
              <span className="text-primary font-weight-medium text-small">
                {truncatedAddress}
              </span>

              <div
                onClick={() => handleCopyAddress(address)}
                className="text-secondary px-3"
                style={{
                  fontSize: "12px",
                  cursor: "pointer",
                }}
              >
                {isAddressCopied ? (
                  <FontAwesomeIcon icon={faCheck} className="" />
                ) : (
                  <FontAwesomeIcon icon={faCopy} className="" />
                )}
              </div>
            </div>
          </div>
          <div
            className="d-flex flex-column align-items-center mx-auto px-4 pb-2"
            style={{ width: "400px" }}
          >
            <p className="text-sm">
              This payment method works for Indian bank accounts. If you don't
              have an Indian bank account, try our partner&nbsp;
              <span
                className="text-primary"
                style={{ cursor: "pointer" }}
                onClick={() => {
                  handleBuyAlgos(tokenPriceCurrency, fundWalletAddress);
                  closeModal();
                }}
              >
                Onramp.money
              </span>
            </p>
          </div>
        </div>
      </Modal>
    </>
  );
};

export default CryptoFundOnRampModal;
