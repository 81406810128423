import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { appActions } from "../../context/app-slice";
import { Link } from "react-router-dom";
import MenuIcon from "@mui/icons-material/Menu";
import SidebarMobile from "../sidebar/SidebarMobile";
import DarkMode from "../common/theme/DarkMode";
import { Popover } from "react-tiny-popover";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faArrowLeft,
  faArrowLeftLong,
  faMoon,
  faSearch,
  faSortDown,
  faSun,
  faUser,
  faWallet,
} from "@fortawesome/free-solid-svg-icons";
import NavWallet from "./NavWallet";
import { getImageUrl, nativeToken } from "../../config";
import { useWalletView } from "../../hooks/useWalletView";
import NavDropdown from "./NavDropdown";
import useNavbar from "./useNavbar";
import CurrencyDropdown from "../currencyDropdown/CurrencyDropdown";
import axios from "axios";
import { Button, Tooltip } from "@mui/material";
import Networkmodal from "../Networkmodal/Networkmodal";
import SearchDropdown from "../searchDropdown/SearchDropdown";
import LightModeIcon from "@mui/icons-material/LightMode";
import ProfileDropdown from "../profileDropdown/ProfileDropdown";

const Navbar = () => {
  const [isWalletPopOpen, setIsWalletPopOpen] = useState(false);
  const [isNavDropdownPopOpen, setIsNavDropdownPopOpen] = useState(false);
  const [wallet, setWallet] = useState(null);
  const [marketplace, setMarketplace] = useState(null);
  const [blockchainobject, setBlockchainobject] = useState(false);
  const [openAccountModal, setopenAccountModal] = useState(false);
  const dispatch = useDispatch();
  const appCtx = useSelector((state) => state.app);
  const [searchTerm, setSearchTerm] = useState("");
  const {
    collapsed,
    userId,
    visible,
    currencyvisible,
    open,
    handleVisible,
    handleCurrencyVisible,
    setVisible,
    setCurrencyVisible,
    searchVisible,
    setSearchVisible,
    mobileSearchVisible,
    setMobileSearchVisible,
    profileVisible,
    setProfileVisible,
    handleProfileVisible,
  } = useNavbar();



  const width = window.screen.width;
  let isMobileDevice = width < 600;



  useEffect(() => {
    if (isMobileDevice) {
      dispatch(appActions.setIsSideBarOpen(false));
    }
  }, [appCtx.blockchain]);

  const toggle = () =>
    dispatch(appActions.setIsSideBarOpen(!appCtx.isSideBarOpen));

  useEffect(() => {
    if (appCtx?.isDarkMode) {
      document.querySelector("body").setAttribute("data-theme-version", "dark");
    } else {
      document
        .querySelector("body")
        .setAttribute("data-theme-version", "light");
    }
  }, [appCtx?.isDarkMode]);
  console.log("appCtx", appCtx);

  const walletAddress = appCtx.walletAddress.find(
    (addr) => addr.blockchain === appCtx.blockchain
  )?.address;
  const themedColor = `${appCtx?.isDarkMode ? "white" : "black"}`;

  useEffect(() => {
    if (appCtx.authToken) {
      let config = {
        url: `${process.env.REACT_APP_URL_BLOCKCHAIN_SERVICE}/user/blockchain/account?wallet=TALEWALLET`,
        method: "get",
        headers: {
          "Content-Type": "application/json",
          "X-Auth-Token": appCtx.authToken,
        },
      };
      axios(config).then((response) => {
        let data = response.data;
        const blockchainObjects = data.reduce((obj, item) => {
          obj[item.blockchain] = true;
          return obj;
        }, {});

        const blockchainObjectsWithAllChain = {
          ...blockchainObjects,
          ALL: true,
        };

        setBlockchainobject(blockchainObjectsWithAllChain);
      });
    }
  }, [appCtx.blockchain]);

  console.log("blockchainobject", blockchainobject);

  const options = nativeToken;
  const filteredOptions = Object.keys(options).filter(
    (key) => !blockchainobject[key]
  );





  return (
    <div
      className="fixed-top px-1 px-md-5"
      style={{ backgroundColor: "#20228A", zIndex: "900" }}
    >
      <div className=" ">
        <div class="header-content">
          <nav class="navbar navbar-expand">
            <div class="collapse navbar-collapse justify-content-between align-items-center">
              <div class="d-flex flex-row align-items-center">
                <div>
                  <Link to="/">
                    <img
                      src="https://taleinvest.blob.core.windows.net/tale-invest-assets/tale-invest-logo-sm.png"
                      alt=""
                      style={{
                        width: "40px",
                        height: "40px",
                      }}
                    />
                  </Link>
                </div>
                <div
                  class="cursor-pointer ms-3 ms-md-5 "
                  onClick={toggle}
                  style={{ cursor: "pointer" }}
                >
                  {!appCtx?.isSideBarOpen ? (
                    <div class="hamburger">
                      <span class="line"></span>
                      <span class="line"></span>
                      <span class="line"></span>
                    </div>
                  ) : (
                    <div className="text-white">
                      <FontAwesomeIcon
                        icon={faArrowLeftLong}
                        style={{ fontSize: "20px" }}
                      />
                    </div>
                  )}
                </div>
              </div>
              {appCtx?.isSideBarOpen && isMobileDevice && <SidebarMobile />}

              {/* search token  */}
              <div class="header-left d-none d-md-block">
                <Popover
                  isOpen={searchVisible}
                  className="z-10"
                  positions={["bottom", "right"]}
                  padding={10}
                  reposition={false}
                  onClickOutside={() => setSearchVisible(false)}
                  content={({ position }) => (
                    <div
                      className={` z-20 relative  rounded overflow-hidden bg-white dark:bg-gray-700 '
                                            } text-gray-600  shadow-md bg-inherit`}
                    >
                      <SearchDropdown
                        searchVisible={searchVisible}
                        setSearchVisible={setSearchVisible}
                        searchTerm={searchTerm}
                        setSearchTerm={setSearchTerm}
                      />
                    </div>
                  )}
                >
                  <div
                    class="input-group   "
                    onClick={(e) => {
                      setSearchVisible(true);
                    }}
                    style={{
                      width: `${isMobileDevice ? "120px" : "300px"}`,
                    }}
                  >
                    <input
                      onChange={(e) => {
                        setSearchTerm(e.target.value);
                      }}
                      type="text"
                      class="form-control"
                      placeholder="Search Token"
                    />
                    <span class="input-group-text">
                      <a href="javascript:void(0)">
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          // xmlns:xlink="http://www.w3.org/1999/xlink"
                          width="24px"
                          height="24px"
                          viewBox="0 0 24 24"
                          version="1.1"
                          class="svg-main-icon"
                        >
                          <g
                            stroke="none"
                            stroke-width="1"
                            fill="none"
                            fill-rule="evenodd"
                          >
                            <rect x="0" y="0" width="24" height="24"></rect>
                            <path
                              d="M14.2928932,16.7071068 C13.9023689,16.3165825 13.9023689,15.6834175 14.2928932,15.2928932 C14.6834175,14.9023689 15.3165825,14.9023689 15.7071068,15.2928932 L19.7071068,19.2928932 C20.0976311,19.6834175 20.0976311,20.3165825 19.7071068,20.7071068 C19.3165825,21.0976311 18.6834175,21.0976311 18.2928932,20.7071068 L14.2928932,16.7071068 Z"
                              fill="var(--primary)"
                              fill-rule="nonzero"
                              opacity="0.3"
                            ></path>
                            <path
                              d="M11,16 C13.7614237,16 16,13.7614237 16,11 C16,8.23857625 13.7614237,6 11,6 C8.23857625,6 6,8.23857625 6,11 C6,13.7614237 8.23857625,16 11,16 Z M11,18 C7.13400675,18 4,14.8659932 4,11 C4,7.13400675 7.13400675,4 11,4 C14.8659932,4 18,7.13400675 18,11 C18,14.8659932 14.8659932,18 11,18 Z"
                              fill="var(--primary)"
                              fill-rule="nonzero"
                            ></path>
                          </g>
                        </svg>
                      </a>
                    </span>
                  </div>
                </Popover>
              </div>
              <ul class="navbar-nav header-right">
                {/* search bar only for mobile */}
                <li class="nav-item dropdown notification_dropdown d-md-none">
                  <Popover
                    isOpen={mobileSearchVisible}
                    className="z-10"
                    positions={["bottom", "right"]}
                    padding={10}
                    reposition={false}
                    onClickOutside={() => setMobileSearchVisible(false)}
                    content={({ position }) => (
                      <div
                        className={` z-20 relative  rounded overflow-hidden bg-white dark:bg-gray-700 '
                                            } text-gray-600  shadow-md bg-inherit`}
                      >
                        <SearchDropdown
                          searchVisible={mobileSearchVisible}
                          setSearchVisible={setMobileSearchVisible}
                          searchTerm={searchTerm}
                          setSearchTerm={setSearchTerm}
                        />
                      </div>
                    )}
                  >
                    <button
                      onClick={() => {
                        setMobileSearchVisible(true);
                      }}
                      class="nav-link bell dz-theme-mode"
                    >
                      <FontAwesomeIcon
                        icon={faSearch}
                        style={{ color: "white" }}
                      />
                    </button>
                  </Popover>
                </li>

                {/* chain dropdown section  */}
                <li class="nav-item dropdown notification_dropdown">
                  <Popover
                    isOpen={currencyvisible}
                    className="z-10"
                    positions={["bottom", "right"]}
                    padding={10}
                    reposition={false}
                    onClickOutside={() => setCurrencyVisible(false)}
                    content={({ position }) => (
                      <div
                        className={` z-20 relative right-32  rounded-md overflow-hidden bg-white dark:bg-gray-700 '
                                            } text-gray-600  shadow-md bg-inherit`}
                      >
                        <CurrencyDropdown
                          currencyvisible={currencyvisible}
                          userId={userId}
                          handleCurrencyVisible={handleCurrencyVisible}
                          setCurrencyVisible={setCurrencyVisible}
                          blockchainobject={blockchainobject}
                          filteredOptions={filteredOptions}
                          setopenAccountModal={setopenAccountModal}
                        />
                      </div>
                    )}
                  >
                    <button
                      onClick={(e) => {
                        handleCurrencyVisible(e);
                      }}
                      className="nav-link "
                      style={{
                        display: "flex",
                        flexDirection: "row",
                        justifyContent: "center",
                        alignItems: "center",
                        gap: "3px",
                      }}
                    >
                      <FontAwesomeIcon
                        icon={faWallet}
                        style={{ color: "white" }}
                      />
                      <span
                        className="text-white ms-2 my-0"
                        style={{ fontSize: "13px" }}
                      >
                        {appCtx.blockchain}
                      </span>
                      <FontAwesomeIcon
                        icon={faSortDown}
                        style={{
                          color: "white",
                          width: "13px",
                          height: "13px",
                        }}
                      />
                    </button>
                  </Popover>
                </li>

                {/* wallet section  */}
                {/* <li class="nav-item dropdown notification_dropdown">
                  <Popover
                    isOpen={isWalletPopOpen}
                    className="z-10"
                    positions={["bottom", "right"]}
                    padding={20}
                    reposition={false}
                    onClickOutside={() => setIsWalletPopOpen(false)}
                    content={({ position }) => (
                      <div
                        className={` z-20 relative right-32  rounded-md overflow-hidden bg-white dark:bg-gray-700 '
                                            } text-gray-600  shadow-md bg-inherit`}
                      >
                        <NavWallet
                          usersNativeTokenBalance={usersNativeTokenBalance}
                        />
                      </div>
                    )}
                  >
                    <button
                      onClick={() => setIsWalletPopOpen(!isWalletPopOpen)}
                      class="nav-link  menu-wallet d-flex justify-content-center align-items-center"
                    >
                      <FontAwesomeIcon
                        icon={faWallet}
                        style={{ color: "white" }}
                      />
                      <span
                        class="text-white ms-2"
                        style={{ fontSize: "13px" }}
                      >
                        {usersNativeTokenBalance}{" "}
                        {nativeToken[appCtx?.blockchain]}
                      </span>
                    </button>
                  </Popover>
                </li> */}
                <li class="nav-item dropdown notification_dropdown">
                  <button
                    onClick={() => {
                      dispatch(appActions.toggleDarkMode());
                    }}
                    class="nav-link bell dz-theme-mode"
                  >
                    {appCtx?.isDarkMode ? (
                      <LightModeIcon style={{ color: "white" }} />
                    ) : (
                      <FontAwesomeIcon
                        icon={faMoon}
                        style={{ color: "white" }}
                      />
                    )}
                  </button>
                </li>

                <li>
                  <div class="dropdown header-profile2">
                    <a
                      class="nav-link"
                      href="javascript:void(0);"
                      role="button"
                      data-bs-toggle="dropdown"
                      aria-expanded="false"
                    >
                      <div class="header-info2 d-flex align-items-center">
                        <div class="d-flex align-items-center sidebar-info">
                          <div>
                            {walletAddress && (
                              <p class="mb-0 text-white pe-3">
                                {" "}
                                {`${walletAddress?.slice(
                                  0,
                                  6
                                )} .... ${walletAddress?.slice(
                                  walletAddress?.length - 5,
                                  walletAddress?.length
                                )}`}
                              </p>
                            )}
                          </div>
                        </div>
                      </div>
                    </a>
                  </div>
                </li>

                <li class="nav-item dropdown notification_dropdown">
                  <Popover
                    isOpen={profileVisible}
                    className="z-10"
                    positions={["bottom", "left"]}
                    padding={10}
                    reposition={false}
                    onClickOutside={() => setProfileVisible(false)}
                    content={({ position }) => (
                      <div
                        className={` z-20 relative right-75  rounded-md overflow-hidden  '
                                            } text-gray-600  shadow-md bg-inherit`}
                        style={{ marginRight: "200px" }}
                      >
                        {/* <NavDropdown /> */}
                        <ProfileDropdown
                          currencyvisible={profileVisible}
                          handleCurrencyVisible={handleProfileVisible}
                          setCurrencyVisible={setProfileVisible}
                        />
                      </div>
                    )}
                  >
                    <button
                      onClick={() => setProfileVisible(!profileVisible)}
                      class="nav-link  menu-wallet d-flex justify-content-center align-items-center"
                    >
                      <FontAwesomeIcon
                        icon={faUser}
                        style={{ color: "white" }}
                      />
                    </button>
                  </Popover>
                </li>
              </ul>
            </div>
          </nav>
        </div>
      </div>

      <Networkmodal
        openModal={openAccountModal}
        setOpenModal={setopenAccountModal}
        filteredOptions={filteredOptions}
      />
    </div>
  );
};

export default Navbar;
